import React from 'react';
import Gallery from 'react-grid-gallery';

function IchiGallery () {
    
    
 
const IMAGES =
[{
        src: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/weird_dog.jpg",
        thumbnail: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/weird_dog_thumb.jpg",
        thumbnailWidth: 250,
        thumbnailHeight: 334,
        tags: [{value: "Ichigo", title: "Ichigo"},{value: "Dog", title: "Dog"}],
        caption: "Written as 一隻狗, Ichigo means a dog in Chinese"
},
{
        src: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/Painting.jpg",
        thumbnail: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/Painting_thumb.jpg",
        thumbnailWidth: 444,
        thumbnailHeight: 334,
        tags: [{value: "Ichigo", title: "Ichigo"},{value: "Strawberry", title: "Strawberry"}],
        caption: "Written as いちご, Ichigo means strawberry in Japanese"
},
 
{
        src: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/Ichigo_walk.jpg",
        thumbnail: "https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/Ichigo_walk_thumb.jpg",
        thumbnailWidth: 263,
        thumbnailHeight: 350,
        tags: [{value: "Ichigo", title: "Ichigo"},{value: "15", title: "15"}],
        caption: "Written as 一五, Ichigo means 15 in Japanese"
}]
 
return (
        <Gallery images={IMAGES} enableImageSelection={false}/>
);
    
    
}

export default IchiGallery