
import worldmap from '../static/world-50m.json';
import React, { Component } from "react"
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
  Annotation,
} from "react-simple-maps"

import { Button, Typography} from '@material-ui/core';

const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto",
}

class Map extends Component {
  constructor() {
    super()
    this.state = {
      center: [0,20],
      zoom: 1,
      cities: [
        { name: "Birthplace", coordinates: [-83.595140,41.674294] },
        { name: "Primary School", coordinates: [120.675769,24.183115] },
        { name: "High School", coordinates: [-122.217706,47.572164] },
        { name: "College", coordinates:[-118.444500,34.069011]},
        { name: "Now", coordinates: [-121.971456,37.32855] },
      ]
    }
    this.handleCitySelection = this.handleCitySelection.bind(this)
    this.handleReset = this.handleReset.bind(this)
    
    this.setBirthplace = this.setBirthplace.bind(this);
    this.setPrimarySchool = this.setPrimarySchool.bind(this);
    this.setHighSchool = this.setHighSchool.bind(this);
    this.setCollege = this.setCollege.bind(this);
    this.setNow = this.setNow.bind(this);
    
  }
  handleCitySelection(evt) {
    const cityId = evt.target.getAttribute("data-city")
    const city = this.state.cities[cityId]
    this.setState({
      center: city.coordinates,
      zoom: 5,
    })
  }


  setBirthplace() {
    this.setState({
      center: [-83.595140,41.674294],
      zoom: 10,
    })
  }
  
  setPrimarySchool() {
    this.setState({
      center: [120.675769,24.183115],
      zoom: 5,
    })
  }

  setHighSchool() {
    this.setState({
      center: [-122.217706,47.572164],
      zoom: 10,
    })
  }
  
  setCollege() {
    this.setState({
      center: [-118.444500,34.069011],
      zoom: 28,
    })
  }
  
  setNow() {
    this.setState({
      center:  [-121.971456,37.32855] ,
      zoom: 28,
    })
  }


  
  handleReset() {
    this.setState({
      center: [0,20],
      zoom: 1,
    })
  }
  render() {
    return (
      <div>
        
        <div style={wrapperStyles}>
          <ComposableMap
            projectionConfig={{
              scale: 205,
            }}
            width={980}
            height={551}
            style={{
              width: "100%",
              height: "auto",
            }}
            >
            <ZoomableGroup center={this.state.center} zoom={this.state.zoom}>
              <Geographies geography={worldmap}>
                {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                  <Geography
                    key={i}
                    geography={geography}
                    projection={projection}
                    style={{
                      default: {
                        fill: "#8BB8E8",
                        stroke: "#2774AE",
                        strokeWidth: 0.75,
                        outline: "none",
                      },
                      hover: {
                        fill: "#8BB8E8",
                        stroke: "#2774AE",
                        strokeWidth: 0.75,
                        outline: "none",
                      },
                      pressed: {
                        fill: "#8BB8E8",
                        stroke: "#2774AE",
                        strokeWidth: 0.75,
                        outline: "none",
                      },
                    }}
                  />
                ))}
              </Geographies>
              <Markers>
                {
                  this.state.cities.map((city, i) => (
                    <Marker key={i} marker={city}>
                      <circle
                        cx={0}
                        cy={0}
                        r={5}
                        fill="#FFC72C"
                        stroke="#FFB81C"
                      />
                    </Marker>
                  ))
                }
              </Markers>
              <Annotation
              dx={ 20 }
              dy={ 5 }
              subject={ [ -83.595140,41.674294 ] }
              strokeWidth={ 1 }
              stroke="#808080"
              >
              <text>{ "Toledo, OH" }</text>
              </Annotation>
              <Annotation
              dx={ 20 }
              dy={ 5 }
              subject={ [ 120.675769,24.183115 ] }
              strokeWidth={ 1 }
              stroke="#808080"
              >
              <text>{ "Taichung, Taiwan" }</text>
              </Annotation>            
              <Annotation
              dx={ 20 }
              dy={ 5 }
              subject={ [ -122.217706,47.572164 ] }
              strokeWidth={ 1 }
              stroke="#808080"
              >
              <text>{ "Mercer Island, WA" }</text>
              </Annotation>
              <Annotation
              dx={ 20 }
              dy={ 5 }
              subject={ [ -118.444500,34.069011 ] }
              strokeWidth={ 1 }
              stroke="#808080"
              >
              <text>{ "Los Angeles, CA" }</text>
              </Annotation>
              <Annotation
              dx={ 20 }
              dy={ 5 }
              subject={ [-121.971456,37.32855 ] }
              strokeWidth={ 1 }
              stroke="#808080"
              >
              <text>{ "San Jose, CA" }</text>
              </Annotation>
              </ZoomableGroup>
          </ComposableMap>
        </div>
        
        <div style={wrapperStyles}>
          {
            // this.state.cities.map((city, i) => (
            //   <button
            //     key={i}
            //     className="btn px1"
            //     data-city={i}
            //     onClick={this.handleCitySelection}
            //     >
            //     { city.name }
            //   </button>
            // ))
          }
          <Button onClick={this.setBirthplace}>
            { "Birthplace" }
          </Button>
          <Button  onClick={this.setPrimarySchool}>
            { "Primary School" }
          </Button>
          <Button onClick={this.setHighSchool}>
            { "High School" }
          </Button>          
          <Button  onClick={this.setCollege}>
            { "College" }
          </Button>   
          <Button  onClick={this.setNow}>
            { "Now" }
          </Button>
          <Button  onClick={this.handleReset}>
            { "Zoom" }
          </Button>
        </div>
      </div>
    )
  }
}

export default Map