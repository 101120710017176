import React from 'react';
import {Grid, Box, Typography, CardMedia} from '@material-ui/core/';

function Intro () {
    return(
      <Grid direction="row">
        
          <Box my={4}>
            <Typography variant="h6" component="h1" gutterBottom>
              Daniel 'NioNio' Chang
            </Typography>
            <Typography variant="h6" component="h1" gutterBottom>
              Common Name, Unique Approach
            </Typography>
            <Typography variant="h6" component="h1" gutterBottom>
              Connect Dots Wirelessly, Deliver Bottomline
            </Typography>
           </Box>
       
        <Box >
         <img src="https://dannio-homepage-pictures.s3-us-west-2.amazonaws.com/ichigo_no_background.png"/>
        </Box>  
      </Grid>
        )
}


export default Intro