import React from 'react';
import Radar from 'react-d3-radar';
import {Box, Typography} from '@material-ui/core/';


function Skills () {
    
return(    
<div>

<Box width={3/5}>

<Typography align="center">Languages</Typography>
<Radar
  width={500}
  height={500}
  padding={70}
  domainMax={10}
  highlighted={null}
  data={{
    variables: [
      {key: 'English', label: 'English'},
      {key: 'Chinese', label: 'Chinese'},
      {key: 'Japanese', label: 'Japanese'},
      {key: 'Spanish', label: 'Spanish'},
    ],
    sets: [
      {
        color: 'blue',
        label: 'Language',
        values: {
          English: 9.5,
          Chinese: 8.5,
          Japanese: 6.5,
          Spanish: 4,
        },
      },
    ],
  }}
/>
</Box>

<Box width={3/5}>

<Typography align="center" >Networking</Typography>
<Radar
  width={500}
  height={500}
  padding={70}
  domainMax={10}
  highlighted={null}
  
  data={{
    variables: [
      {key: 'LTE', label: 'LTE'},
      {key: 'WIFI', label: 'WIFI'},
      {key: 'Bluetooth', label: 'Bluetooth'},
      {key: 'Zigbee', label: 'Zigbee'},
      {key: 'LAN', label: 'LAN'},
      {key: 'FiveG', label: '5G'},
    ],
    sets: [
      {
        label: 'Networking',
        values: {
          LTE: 10,
          WIFI: 8,
          Bluetooth: 7,
          Zigbee: 9,
          LAN: 8,
          FiveG: 6.5,
        },
      },
    ],
    color: 'brown'
  }}
/>
</Box>

<Box width={3/5}>

<Typography align="center" >System</Typography>
<Radar
  width={500}
  height={500}
  padding={70}
  domainMax={10}
  highlighted={null}
  data={{
    variables: [
      {key: 'Linux', label: 'Linux'},
      {key: 'Windows', label: 'Windows'},
      {key: 'AWS', label: 'AWS'},
      {key: 'Android', label: 'Android'},
      {key: 'RTOS', label: 'RTOS'},
      {key: 'iOS', label: 'iOS'},
    ],
    sets: [
      {
        color: 'green',
        label: 'SysAdmin',
        values: {
          Linux: 8.5,
          Windows: 7.5,
          AWS: 9,
          Android: 6.5,
          RTOS: 6.5,
          iOS: 6.5,
        },
      },
    ],
  }}
/>
</Box>

<Box width={3/5}>

<Typography align="center">Programming</Typography>
<Radar
  width={500}
  height={500}
  padding={70}
  domainMax={10}
  highlighted={null}
  data={{
    variables: [
      {key: 'Javascript', label: 'Javascript'},
      {key: 'Python', label: 'Python'},
      {key: 'CSharp', label: 'C#'},
      {key: 'CPlusPlus', label: 'C++'},
      {key: 'Labview', label: 'Labview'},
    ],
    sets: [
      {
        color: 'yellow',
        label: 'My Scores',
        values: {
          Javascript: 9,
          Python: 7.5,
          CSharp: 6,
          CPlusPlus: 5.5,
          Labview: 5,
        },
      },
    ],
  }}
/>
</Box>

<Box width={3/5}>


<Typography align="center">Data</Typography>
<Radar
  width={500}
  height={500}
  padding={70}
  domainMax={10}
  highlighted={null}
  data={{
    variables: [
      {key: 'MySQL', label: 'MySQL'},
      {key: 'InfluxDB', label: 'InfluxDB'},
      {key: 'MongoDB', label: 'MongoDB'},
      {key: 'Redis', label: 'Redis'},
      {key: 'Ethereum', label: 'Ethereum'},
    ],
    sets: [
      {
        color: 'purple',
        label: 'My Scores',
        values: {
          MySQL: 6,
          InfluxDB: 9.5,
          MongoDB: 8,
          Redis: 5,
          Ethereum: 5,
        },
      },
    ],
  }}
/>
</Box>


</div>

)
}

export default Skills