import React, {useState} from 'react';
import './App.css';
import { Toolbar, Button, Typography, Link, Container, Box } from '@material-ui/core';
import Map from './component/Map';
import Resume from './component/Resume';
import Intro from './component/Intro';
import Skills from './component/Skills';
import IchiGallery from './component/Gallery';

import HomeIcon from '@material-ui/icons/Home';
import PlaceIcon from '@material-ui/icons/PersonPinCircle';
import TodayIcon from '@material-ui/icons/Today';
import ComputerIcon from '@material-ui/icons/Computer';
import QuestionIcon from '@material-ui/icons/DeviceUnknown';


function App() {
  
  const [introPage, setIntroPage] = useState("block");
  const [mapPage, setMapPage] = useState("none");
  const [resumePage, setResumePage] = useState("none");
  const [skillsPage, setSkillsPage] = useState("none");
  const [galleryPage, setGalleryPage] = useState("none");


  function switchPage (index) {
    switch (index) {
      case 0: setIntroPage("block"); setMapPage("none"); setResumePage("none"); setSkillsPage("none"); setGalleryPage("none"); break;
      case 1: setIntroPage("none"); setMapPage("block"); setResumePage("none"); setSkillsPage("none"); setGalleryPage("none"); break;
      case 2: setIntroPage("none"); setMapPage("none"); setResumePage("block"); setSkillsPage("none"); setGalleryPage("none"); break;
      case 3: setIntroPage("none"); setMapPage("none"); setResumePage("none"); setSkillsPage("block"); setGalleryPage("none"); break;
      case 4: setIntroPage("none"); setMapPage("none"); setResumePage("none"); setSkillsPage("none"); setGalleryPage("block"); break;

    }
  }

  
  return (
  <div>
  <Container maxWidth="lg">
     <Box my={4}>
        <Toolbar component="nav">
          <Button onClick={()=>switchPage(0)}><HomeIcon/></Button>
          <Button onClick={()=>switchPage(1)}><PlaceIcon/></Button>
          <Button onClick={()=>switchPage(2)}><TodayIcon/></Button>
          <Button onClick={()=>switchPage(3)}><ComputerIcon/></Button>
          <Button onClick={()=>switchPage(4)}><QuestionIcon/></Button>
        </Toolbar>
    </Box>
    <Box display={introPage}>
     <Intro/>
    </Box>
    <Box display={skillsPage}>
     <Skills/>
    </Box>
  </Container>
  <Box display={resumePage}>
     <Resume/>
  </Box>
  <Box display={mapPage}>
    <Map width={4/5}/>
  </Box>  
  <Box display={galleryPage} width={2/3}>
     <IchiGallery/>
  </Box>

    </div>
    
  );
}

export default App;
