import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import SvgIcon from '@material-ui/core/SvgIcon';


function Resume () {

  // function VodaIcon (props) {
  //   return (
  //     <SvgIcon {...props}>
  //       <path d="M 119.441406 14.328125 C 166.90625 -4.589844 222.195312 -3.28125 268.394531 18.691406 C 255.230469 16.691406 241.839844 18.199219 228.808594 20.402344 C 193.417969 27.085938 159.785156 43.738281 133.496094 68.476562 C 108.289062 93.253906 90.625 126.128906 85.636719 161.300781 C 82.335938 186.222656 85.394531 212.363281 96.578125 235.046875 C 108.101562 258.847656 128.386719 278.367188 152.835938 288.441406 C 176.394531 298.410156 203.722656 298.300781 227.585938 289.402344 C 263.390625 276.261719 289.117188 240.78125 292.058594 202.875 C 293.910156 178.03125 287.867188 151.601562 271.175781 132.546875 C 255.242188 113.894531 231.9375 103.621094 208.617188 97.773438 C 207.367188 74.773438 218.203125 52.050781 235.386719 37.09375 C 244.929688 28.488281 256.773438 23.003906 269.019531 19.492188 L 269.949219 19.171875 C 305.007812 35.984375 334.796875 63.710938 353.777344 97.75 C 370.039062 126.730469 378.519531 160.125 377.679688 193.390625 C 377.519531 236.40625 361.441406 278.996094 333.878906 311.886719 C 307.816406 343.253906 271.363281 365.816406 231.632812 374.851562 C 191.796875 384.042969 148.9375 380.078125 111.65625 363.148438 C 75.175781 346.855469 44.269531 318.53125 24.609375 283.691406 C 8.253906 254.738281 -0.398438 221.355469 0.320312 188.050781 C 0.398438 146.554688 15.132812 105.378906 40.859375 72.929688 C 61.410156 47.019531 88.671875 26.417969 119.441406 14.328125 Z M 119.441406 14.328125 "/>
  //       <path d="M 228.808594 20.402344 C 241.839844 18.199219 255.230469 16.691406 268.394531 18.691406 L 270.285156 19.011719 L 269.019531 19.492188 C 256.773438 23.003906 244.929688 28.488281 235.386719 37.09375 C 218.203125 52.050781 207.367188 74.773438 208.617188 97.773438 C 231.9375 103.621094 255.242188 113.894531 271.175781 132.546875 C 287.867188 151.601562 293.910156 178.03125 292.058594 202.875 C 289.117188 240.78125 263.390625 276.261719 227.585938 289.402344 C 203.722656 298.300781 176.394531 298.410156 152.835938 288.441406 C 128.386719 278.367188 108.101562 258.847656 96.578125 235.046875 C 85.394531 212.363281 82.335938 186.222656 85.636719 161.300781 C 90.625 126.128906 108.289062 93.253906 133.496094 68.476562 C 159.785156 43.738281 193.417969 27.085938 228.808594 20.402344 Z M 228.808594 20.402344 "/>      </SvgIcon>
  //   );
  // }

    return(
      <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2018 - present"
            iconStyle={{ background: '#E40000', color: '#fff' }}
            //icon={<VodaIcon color="fff"/> }
          >
            <h3 className="vertical-timeline-element-title">Vodafone</h3>
            <h4 className="vertical-timeline-element-subtitle">Redwood City, CA</h4>
            <h4>
              IoT Technical Solution Expert
            </h4>
            <p>
            Design, build, and manage Vodafone Internet-of-Things Lab in Silicon Valley. Installed NB-IoT, a 5G Low Power Wide Area network to help US-based VIP multinational companies and Bay Area start-up communities accelerate global IoT business time-to-market in Vodafone countries. 
            Conduct interoperability sessions and co-creation exercises with enterprise customers to evaluate IoT technologies and business opportunities.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016 - 2018"
            iconStyle={{ background: '#FF6C0C', color: '#fff' }}
          //  icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Caltech - PowerFlex Systems</h3>
            <h4 className="vertical-timeline-element-subtitle">Pasadena, CA</h4>
            <h4>
              Staff, Incubation Startup Founder
            </h4>
            <p>
            Commercialized Caltech Smart-Grid research.  Electrified 50 garages with dense electric vehicle charging stations using $3M, 10% industry power upgrade cost.   Developed system to actively manage fleet charging based on driver schedule, grid status, electrical topology, and price signals.  Devised financial model to fund edge and cloud operation with carbon credit.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2012 - 2016"
            iconStyle={{ background: '#289B7D', color: '#fff' }}
          //  icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Anritsu Company</h3>
            <h4 className="vertical-timeline-element-subtitle">San Jose, CA</h4>
            <h4>
              Senior Technical Solution Specialist
            </h4>
            <p>
            Spearheaded special projects between Japan HQ and US tier-one telecom carriers, delivered $10M+ of NRE on battery life, IP Media, WiFi, and other carrier tests with fully automated with Android, wireshark, OCR plugins.
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2010 - 2012"
            iconStyle={{ background: '#8BB8E8', color: '#fff' }}
         //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">UCLA Anderson</h3>
            <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
            <h4>
              Master of Business Administration
            </h4>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2007 - 2010"
            iconStyle={{ background: 'rgb(0, 0, 243)', color: '#fff' }}
         //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Aeroflex Corporation</h3>
            <h4 className="vertical-timeline-element-subtitle">Irvine, CA</h4>
            <h4>
              Application Engineer Lead
            </h4>
            <p>
            Led a team of eventually 10 Application Engineers to support activities during North America LTE launch, securing key wins during network infrastructure trials, chipset interoperability, and device certifications.
            </p>
          </VerticalTimelineElement>
    
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2003 - 2007"
            iconStyle={{ background: '#8BB8E8', color: '#fff' }}
         //   icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">UCLA</h3>
            <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
            <h4>
              BS Electrical Engineering
            </h4>
          </VerticalTimelineElement>

    
        </VerticalTimeline>
        
        )
        
}
export default Resume